<template>
  <SuccessBanner
    :key="key"
    :msg="successMsg"
    :active="activeBanner"
    @closeBanner="activeBanner = false"
    :isSuccess="isSuccess"
  />
  <div class="pr-10 pl-10 pt-5">
    <div
      class="header overflow-hidden shadow rounded-lg divide-y divide-gray-200 mt-5"
    >
      <div class="flex justify-between">
        <div
          class="text-lg leading-6 font-medium px-4 py-5 sm:px-6 body__header--text"
        >
          <span v-if="endPoint === 'hostOs'">Operating Systems</span>
          <span v-if="endPoint === 'hostSoftwares'">Softwares</span>
          <span v-if="endPoint === 'hostRoles'">Host Roles</span>
          <span v-if="endPoint === 'hostTypes'">Host Types</span>
          <span v-if="endPoint === 'hostShells'">Host Shells</span>
          <span v-if="endPoint === 'hostFunctions'">Host Functions</span>
          <span v-if="endPoint === 'hostSuppliers'">Host Suppliers</span>
          <span v-if="endPoint === 'datacenters'">Datacenters</span>
          <span v-if="endPoint === 'hostServices'">Host Services</span>
        </div>
        <div class="px-4 py-5 sm:px-6 flex-shrink-0">
          <button
            @click="openNewForm = !openNewForm"
            type="button"
            class="relative inline-flex items-center px-1 py-1 border border-transparent shadow-sm text-sm font-medium rounded-md text-white body__button focus:outline-none "
          >
            <PlusSmIcon class="h-5 w-5" aria-hidden="true" />

            <!--  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    class="h-3 w-3"
                    viewBox="0 0 20 20"
                   fill="currentColor"
                   >
                    <path
                      fill-rule="evenodd"
                      d="M10 3a1 1 0 011 1v5h5a1 1 0 110 2h-5v5a1 1 0 11-2 0v-5H4a1 1 0 110-2h5V4a1 1 0 011-1z"
                      clip-rule="evenodd"
                    />
                   </svg> -->
          </button>
        </div>
      </div>

      <div class="px-4 py-5 sm:p-6" v-if="openNewForm">
        <div class="body__table--body shadow overflow-hidden sm:rounded-md">
          <NewOS
            v-if="endPoint !== 'datacenters'"
            :endPoint="endPoint"
            @closeForm="openNewForm = false"
            @activeBanner="
              (activeBanner = true),
                getHostData(),
                (key = !key),
                (openNewForm = false)
            "
            @successMsg="successMsg = $event"
            @success="isSuccess = $event"
          />
          <NewDatacenter
            v-if="endPoint === 'datacenters'"
            :endPoint="endPoint"
            @closeForm="openNewForm = false"
            @activeBanner="
              (activeBanner = true),
                getHostData(),
                (key = !key),
                (openNewForm = false)
            "
            @successMsg="successMsg = $event"
            @success="isSuccess = $event"
          />
        </div>
      </div>

      <div class="px-4 pr-6 pl-6 pb-6">
        <div class="flex justify-end pt-2 pb-2">
          <SearchFilter
            v-model:search="search"
            :inputPlaceholder="$t('header.search')"
          />
        </div>
        <div class="body__table--body shadow overflow-hidden sm:rounded-md">
          <ul role="list" class="divide-y divide-gray-200">
            <li v-for="(data, idx) in filteredData()" :key="data.id">
              <a href="#" class="block hover:bg-gray-50">
                <div class="px-4 py-4 sm:px-6">
                  <div class="flex items-center justify-between">
                    <div @click="openEditForm(idx)">
                      <p
                        class="text-sm font-medium body__table--body__text truncate"
                      >
                        {{ data.name }}
                      </p>
                    </div>
                    <div
                      class="mt-2 flex items-center text-sm text-gray-500 sm:mt-0"
                    >
                      <td class="pr-3 pl-3">
                        <div class="inline-flex">
                          <button
                            @click="openEditForm(idx)"
                            :class="
                              `p-1 shadow-sm border-2 border-gray-500 rounded-md body__table--button cursor-pointer tooltip text-gray-600 mr-1 z-10`
                            "
                          >
                            <HoverTooltip text="Edit" />
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              class="h-4 w-4"
                              fill="none"
                              viewBox="0 0 24 24"
                              stroke="currentColor"
                            >
                              <path
                                stroke-linecap="round"
                                stroke-linejoin="round"
                                stroke-width="2"
                                d="M11 5H6a2 2 0 00-2 2v11a2 2 0 002 2h11a2 2 0 002-2v-5m-1.414-9.414a2 2 0 112.828 2.828L11.828 15H9v-2.828l8.586-8.586z"
                              />
                            </svg>
                          </button>
                          <a
                            @click="
                              deleteData(
                                data.id,
                                `Delete Software`,
                                'Do you wish to continue?',
                                'Continue',
                                'Go back',
                                'Software deleted successfully'
                              )
                            "
                            href="javascript:void(0)"
                            :class="
                              `p-1 shadow-sm border-2 border-gray-500 rounded-md body__table--button cursor-pointer tooltip text-gray-600 mr-1`
                            "
                          >
                            <HoverTooltip text="Delete"/>
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              class="h-4 w-4"
                              fill="none"
                              viewBox="0 0 24 24"
                              stroke="currentColor"
                            >
                              <path
                                stroke-linecap="round"
                                stroke-linejoin="round"
                                stroke-width="2"
                                d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16"
                              /></svg
                          ></a>
                        </div>
                      </td>
                    </div>
                  </div>
                </div>
              </a>
              <div v-if="String(this.showIdx) === String(idx) && this.showEdit">
                <EditOS
                  v-if="endPoint !== 'datacenters'"
                  :endPoint="endPoint"
                  :id="data.id"
                  :name="data.name"
                  :comment="data.comment"
                  @closeForm="this.showEdit = false"
                  @activeBanner="
                    (activeBanner = true),
                      getHostData(),
                      (key = !key),
                      (this.showEdit = false)
                  "
                  @successMsg="successMsg = $event"
                  @success="isSuccess = $event"
                />
                <EditDatacenter
                  v-if="endPoint === 'datacenters'"
                  :endPoint="endPoint"
                  :id="data.id"
                  :name="data.name"
                  :code="data.code"
                  :supplierId="data.host_supplier_id"
                  :comment="data.comment"
                  @closeForm="this.showEdit = false"
                  @activeBanner="
                    (activeBanner = true),
                      getHostData(),
                      (key = !key),
                      (this.showEdit = false)
                  "
                  @successMsg="successMsg = $event"
                  @success="isSuccess = $event"
                />
              </div>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
  <ask-confirmation-dialog
    ref="askConfirmationDialog"
  ></ask-confirmation-dialog>
  <loading
    v-model:active="isLoading"
    :can-cancel="false"
    :on-cancel="onCancel"
    :is-full-page="fullPage"
  />
</template>

<script>
import axios from "axios";

import SuccessBanner from "../../../components/SuccessBanner.vue";
import HoverTooltip from "../../../components/HoverTooltip.vue";
import AskConfirmationDialog from "../../../components/AskConfirmationDialog.vue";
import NewOS from "../../../components/configuration/hosting/NewOS.vue";
import NewDatacenter from "../../../components/configuration/hosting/NewDatacenter.vue";
import EditOS from "../../../components/configuration/hosting/EditOS.vue";
import EditDatacenter from "../../../components/configuration/hosting/EditDatacenter.vue";
import SearchFilter from "../../../components/SearchFilter.vue";

import { PlusSmIcon } from "@heroicons/vue/solid";

import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";

export default {
  props: ["endPoint"],
  components: {
    SuccessBanner,
    HoverTooltip,
    AskConfirmationDialog,
    NewOS,
    EditOS,
    NewDatacenter,
    EditDatacenter,
    SearchFilter,
    Loading,
    PlusSmIcon,
  },
  data() {
    return {
      isLoading: true,
      fullPage: false,
      successMsg: "",
      activeBanner: false,
      isSuccess: true,
      key: false,
      hostData: [],
      openNewForm: false,
      showIdx: null,
      showEdit: false,
      search: "",
    };
  },
  methods: {
    openEditForm(idx) {
      if (this.showIdx !== "" && idx != this.showIdx && this.showEdit) {
        this.showIdx = idx;
        this.showEdit = true;
      } else {
        this.showEdit = !this.showEdit;
        this.showIdx = idx;
      }
    },
    async getHostData() {
      try {
        const res = await axios.get(
          `${this.$cookie.getCookie("API")}/api/v1/${this.endPoint}`
        );
        console.log(res.data);
        this.hostData = res.data;
        this.isLoading = false;
      } catch (error) {
        this.errorHandling(error);
        this.isLoading = false;
      }
    },
    filteredData() {
      return this.hostData.filter((data) => {
        return data.name.toLowerCase().includes(this.search.toLowerCase());
      });
    },
    async deleteData(
      id,
      title,
      confirmationMessage,
      confirmButton,
      goBack,
      successMsg
    ) {
      const r = await this.$refs.askConfirmationDialog.show({
        title: title,
        message: confirmationMessage,
        okButton: confirmButton,
        cancelButton: goBack,
        action: "delete",
      });
      if (r) {
        axios
          .delete(
            `${this.$cookie.getCookie("API")}/api/v1/${this.endPoint}/${id}`
          )
          .then((res) => {
            if (res) {
              this.activeBanner = true;
              this.isSuccess = true;
              this.successMsg = successMsg;
              this.key = !this.key;
              this.getHostData();
            }
          })
          .catch((error) => {
            this.activeBanner = true;
            this.isSuccess = false;
            this.successMsg = "Something went wrong";
            this.key = !this.key;
            this.errorHandling(error);
          });
      }
    },
  },
  mounted() {
    this.getHostData();
  },
};
</script>

<style></style>
