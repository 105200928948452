<template>
  <div class="z-20 shadow">
    <div class="add_destination_form">
      <div class="w-full">
        <form
          @submit.prevent="onSubmit(this.id, 'Action successfull.')"
          class="shadow space-y-8 divide-y divide-gray-200 form_padding"
          method="POST"
        >
          <div class="space-y-8 divide-y divide-gray-200">
            <div>
              <div>
                <h3
                  class="text-lg leading-6 font-medium body__header--text capitalize"
                >
                  New datacenter
                </h3>
              </div>
              <br />
              <div class="pt-8 space-y-6 sm:pt-0 sm:space-y-5">
                <div class="space-y-6 sm:space-y-5">
                  <div
                    class="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5"
                  >
                    <label
                      for="cti-origin"
                      class="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
                    >
                      Code
                    </label>
                    <div class="mt-1 sm:mt-0 sm:col-span-2">
                      <input
                        v-model="this.form.code"
                        required=""
                        type="text"
                        name="cti-origin"
                        id="cti-origin"
                        class="rounded max-w-lg block w-full shadow-sm focus:encom_border_input  sm:max-w-xs sm:text-sm border-gray-300"
                      />
                    </div>
                  </div>
                  <div
                    class="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5"
                  >
                    <label
                      for="cti-origin"
                      class="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
                    >
                      Name
                    </label>
                    <div class="mt-1 sm:mt-0 sm:col-span-2">
                      <input
                        v-model="this.form.name"
                        required=""
                        type="text"
                        name="cti-origin"
                        id="cti-origin"
                        class="rounded max-w-lg block w-full shadow-sm focus:encom_border_input  sm:max-w-xs sm:text-sm border-gray-300"
                      />
                    </div>
                  </div>
                  <div
                    class="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5"
                  >
                    <label
                      for="infrastructure"
                      class="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2 capitalize"
                    >
                      supplier
                    </label>
                    <div class="mt-1 sm:mt-0 sm:col-span-2">
                      <select
                        v-model="form.supplier_id"
                        id="infrastructure"
                        name="infrastructure"
                        autocomplete="infrastructure"
                        class="max-w-lg block focus:ring-indigo-500 focus:border-indigo-500 w-full shadow-sm sm:max-w-xs sm:text-sm border-gray-300 rounded-md"
                      >
                        <option
                          v-for="supplier in suppliers"
                          :key="supplier.id"
                          :value="supplier.id"
                          >{{ supplier.name }}</option
                        >
                      </select>
                    </div>
                  </div>
                  <div
                    class="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5"
                  >
                    <label
                      for="cti-destination"
                      class="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
                    >
                      Comment
                    </label>
                    <div class="mt-1 sm:mt-0 sm:col-span-2">
                      <input
                        v-model="this.form.comment"
                        type="text"
                        name="cti-destination"
                        id="cti-destination"
                        class="rounded max-w-lg block w-full shadow-sm focus:encom_border_input  sm:max-w-xs sm:text-sm border-gray-300"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="pt-5">
            <div class="flex justify-end">
              <a
                href="javascript:void(0)"
                @click="$emit('closeForm')"
                class="rounded bg-white py-2 px-4 border border-gray-300 shadow-sm text-sm font-medium text-gray-700 hover:bg-gray-50"
              >
                {{ $t("ticket.cancel") }}
              </a>
              <button
                type="submit"
                :class="
                  `rounded ml-3 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium body__button text-white`
                "
              >
                {{ $t("ticket.send") }}
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";

export default {
  props: ["id", "endPoint"],
  components: {},
  data() {
    return {
      suppliers: [],
      form: {
        name: "",
        comment: "",
        supplier_id: 0,
        code: "",
      },
    };
  },
  methods: {
    async onSubmit(id, msg) {
      let data = {
        name: this.form.name,
        comment: this.form.comment,
        host_supplier_id: this.form.supplier_id,
        code: this.form.code,
      };
      console.log(data);
      axios
        .post(`${this.$cookie.getCookie("API")}/api/v1/${this.endPoint}`, data)
        .then((res) => {
          console.log(res);
          if (res) {
            this.$emit("activeBanner");
            this.$emit("success", true);
            this.$emit("successMsg", msg);
          }
        })
        .catch(function(error) {
          this.errorHandling(error);
          this.$emit("activeBanner");
          this.$emit("success", false);
          this.$emit("successMsg", this.errorDataResponse);
        });
    },
    async getSuppliers() {
      try {
        const res = await axios.get(
          `${this.$cookie.getCookie("API")}/api/v1/hostSuppliers`
        );
        this.suppliers = res.data;
      } catch (error) {
        this.errorHandling(error);
      }
    },
    async getCode() {
      try {
        const res = await axios.get(
          `${this.$cookie.getCookie("API")}/api/v1/helpers/strings/code`
        );
        this.form.code = res.data;
      } catch (error) {
        this.errorHandling(error);
      }
    },
  },
  mounted() {
    this.getSuppliers();
    this.getCode();
  },
};
</script>

<style></style>
